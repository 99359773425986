@font-face {
    font-family: 'SF Pro';
    src: url('./assets/fonts/SF-Pro.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-variation-settings: "opsz" 20.9;
}

@font-face {
    font-family: 'SF Pro';
    src: url('./assets/fonts/SF-Pro.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-variation-settings: "opsz" 20.9;
  }
  
  @font-face {
    font-family: 'SF Pro';
    src: url('./assets/fonts/SF-Pro.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-variation-settings: "opsz" 20.9;
  }
  
  @font-face {
    font-family: 'SF Pro';
    src: url('./assets/fonts/SF-Pro.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-variation-settings: "opsz" 20.9;
  }
  
  @font-face {
    font-family: 'SF Pro';
    src: url('./assets/fonts/SF-Pro.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-variation-settings: "opsz" 20.9;
  }
  
  @font-face {
    font-family: 'SF Pro';
    src: url('./assets/fonts/SF-Pro.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-variation-settings: "opsz" 20.9;
  }
  
